



import { useNavigate } from 'react-router-dom';
import HomeLf from './HomeLf';



var runtime = 0;
function Privacidade() {


const navigate = useNavigate();









  return (
    <>
<HomeLf/>


  <h4 className="py-4 text-center"
      style={{color: '#06BBCC', fontSize: '30px', fontWeight: '800', marginTop: '125px'}}
    
   >Política de Privacidade do Lumera 3D</h4>
    

      <div  >
      <div style={{marginBottom: "50px", paddingLeft: '10%', paddingRight: '10%', color: '#666', fontSize: '20px'}}>

    
    <div style={{textAlign: 'center', width: '100%'}}>

    
    Data de Vigência: 02 de dezembro de 2024 <br/>
No Lumera 3D, a sua privacidade é uma prioridade. Esta Política de Privacidade <br/>
foi elaborada para informar como coletamos, usamos, armazenamos e <br/>
protegemos os seus dados pessoais, em conformidade com a Lei Geral de <br/>
Proteção de Dados Pessoais (LGPD) - Lei nº 13.709/2018. <br/>
Nosso compromisso é garantir transparência e segurança no tratamento das suas <br/>
informações. Ao utilizar o site e nossos serviços, você concorda com os termos <br/>
descritos nesta Política de Privacidade. Por isso, recomendamos que leia <br/>
atentamente este documento. <br/>
Abaixo, explicaremos: <br/>
• Quais dados coletamos e como os utilizamos. <br/>
• Seus direitos como titular de dados pessoais. <br/>
• As medidas que adotamos para proteger suas informações. <br/>
Se restar alguma dúvida, estamos à disposição através do e-mail <br/>
contato@lumera3d.com. Sua confiança é essencial para nós, e nos <br/>
empenhamos em mantê-la com práticas éticas e transparentes. <br/>
Vamos juntos explorar o mundo do 3D de maneira segura e confiável. <br/>
1. DADOS COLETADOS <br/>
O Lumera 3D coleta informações pessoais e não pessoais com o objetivo de <br/>
oferecer uma experiência personalizada e funcional aos nossos usuários. Essas <br/>
informações são divididas em duas categorias principais: <br/>
1.1. Dados fornecidos pelo usuário <br/>
Esses dados são coletados diretamente de você ao acessar ou utilizar nossos <br/>
serviços. Incluem: <br/>
• Informações de Cadastro: Nome completo, endereço de e-mail, e outros <br/>
dados necessários para criar uma conta ou acessar determinados <br/>
recursos. <br/>
• Conteúdo enviado: Modelos 3D, mensagens ou arquivos compartilhados <br/>
por meio da plataforma. <br/>
• Informações de pagamento: Caso utilize serviços pagos, poderão ser <br/>
coletados dados de pagamento (como número do cartão e informações de <br/>
faturamento) por meio de plataformas seguras e autorizadas. <br/>
1.2. Dados coletados automaticamente <br/>
Esses dados são coletados automaticamente durante o uso do site, através de <br/>
ferramentas como cookies e tecnologias similares. Incluem: <br/>
• Dados de Navegação: Endereço IP, tipo de navegador, idioma, sistema <br/>
operacional e configurações do dispositivo. <br/>
• Dados de Interação: Páginas acessadas, tempo de permanência no site, <br/>
cliques e outras ações realizadas. <br/>
• Localização: Dados aproximados da sua localização, baseados no <br/>
endereço IP, para fins analíticos. <br/>
1.3. Dados obtidos de terceiros <br/>
Podemos receber informações de terceiros confiáveis, como serviços de <br/>
autenticação, caso você escolha se cadastrar ou fazer login utilizando <br/>
plataformas como Google ou redes sociais. <br/>
1.4. Finalidade da coleta <br/>
Todos os dados coletados têm como principal objetivo oferecer uma experiência <br/>
otimizada, personalizada e segura. Para mais informações sobre como utilizamos <br/>
esses dados, veja a próxima seção sobre Finalidade do Tratamento de Dados. <br/>
2. FINALIDADE DO TRATAMENTO DOS DADOS <br/>
Os dados coletados pelo Lumera 3D são tratados com respeito à privacidade e <br/>
transparência, sempre em conformidade com a Lei Geral de Proteção de Dados <br/>
Pessoais (LGPD). Abaixo, descrevemos as principais finalidades para o <br/>
tratamento das suas informações: <br/>
2.1. Prestação de serviços <br/>
• Permitir o acesso e uso dos recursos oferecidos pela plataforma, como o <br/>
upload, compartilhamento e gerenciamento de modelos 3D. <br/>
• Fornecer suporte técnico e responder às solicitações dos usuários. <br/>
• Processar pagamentos e gerenciar transações, quando aplicável. <br/>
2.2. Personalização da experiência <br/>
• Personalizar a navegação e os conteúdos exibidos, de acordo com as <br/>
preferências e interações do usuário. <br/>
• Melhorar a experiência do usuário, com base em dados analíticos e <br/>
feedbacks. <br/>
2.3. Comunicação com o usuário <br/>
• Enviar notificações importantes relacionadas ao funcionamento do site, <br/>
como atualizações de políticas, mudanças em funcionalidades ou <br/>
problemas técnicos. <br/>
• Divulgar promoções, novidades e outros materiais relacionados aos nossos <br/>
serviços, desde que previamente autorizado pelo usuário. <br/>
2.4. Cumprimento de obrigações legais <br/>
• Atender a requisitos legais e regulatórios aplicáveis. <br/>
• Responder a solicitações de autoridades governamentais ou judiciais, <br/>
quando exigido. <br/>
2.5. Segurança e prevenção a fraudes <br/>
• Garantir a segurança da plataforma e proteger contra atividades não <br/>
autorizadas ou mal-intencionadas. <br/>
• Monitorar e identificar possíveis violações dos Termos de Uso. <br/>
2.6. Desenvolvimento e melhoria dos serviços <br/>
• Analisar o uso da plataforma para identificar tendências e melhorar <br/>
funcionalidades. <br/>
• Realizar pesquisas e análises de mercado para entender as necessidades <br/>
dos usuários e criar soluções mais eficazes. <br/>
Base Legal <br/>
O tratamento dos seus dados é realizado com base nas hipóteses previstas na <br/>
LGPD, como o cumprimento de obrigações contratuais, o consentimento do <br/>
titular, o legítimo interesse da empresa e o cumprimento de obrigações legais ou <br/>
regulatórias. <br/>
3. POLÍTICA DE COOKIES <br/>
Os cookies são pequenos arquivos de texto armazenados no seu dispositivo ao <br/>
acessar o nosso site. Eles ajudam a melhorar sua experiência de navegação, <br/>
fornecendo funcionalidades essenciais e informações para personalizar nossos <br/>
serviços. No Lumera 3D, utilizamos cookies de forma transparente e em <br/>
conformidade com a Lei Geral de Proteção de Dados Pessoais (LGPD). <br/>
3.1. Tipos de Cookies que Utilizamos <br/>
• Cookies Necessários: <br/>
Garantem o funcionamento adequado do site e não podem ser <br/>
desativados. Incluem, por exemplo, cookies que permitem o login e o <br/>
carregamento de modelos 3D. <br/>
• Cookies de Desempenho: <br/>
Coletam informações sobre como os usuários interagem com o site, <br/>
ajudando-nos a identificar problemas e melhorar sua experiência. <br/>
• Cookies de Funcionalidade: <br/>
Permitem que o site se lembre de suas preferências (como idioma ou <br/>
configurações de exibição) para oferecer uma experiência mais <br/>
personalizada. <br/>
• Cookies de Publicidade e Marketing: <br/>
Utilizados para apresentar anúncios relevantes e medir a eficácia de <br/>
campanhas promocionais. Esses cookies podem ser de terceiros, como <br/>
Google Ads ou redes sociais. <br/>
3.2. Como Gerenciar Cookies <br/>
Você pode gerenciar ou desativar cookies diretamente nas configurações do seu <br/>
navegador. No entanto, desativar alguns cookies pode impactar o funcionamento <br/>
do site. <br/>
• Opções de Configuração: <br/>
o Google Chrome: Gerenciar Cookies no Chrome <br/>
o Mozilla Firefox: Gerenciar Cookies no Firefox <br/>
o Microsoft Edge: Gerenciar Cookies no Edge <br/>
o Safari: Gerenciar Cookies no Safari <br/>
3.3. Cookies de Terceiros <br/>
Em nosso site, podemos utilizar cookies de terceiros para fins analíticos e de <br/>
marketing. Esses cookies são gerenciados pelas respectivas empresas, que <br/>
possuem suas próprias políticas de privacidade e práticas de coleta de dados. <br/>
3.4. Consentimento <br/>
Ao acessar nosso site pela primeira vez, você verá um aviso solicitando seu <br/>
consentimento para o uso de cookies. Sua escolha será registrada, mas você <br/>
poderá revisá-la a qualquer momento nas configurações do site ou do navegador. <br/>
3.5. Atualizações na Política de Cookies <br/>
Esta política poderá ser atualizada periodicamente para refletir mudanças nas <br/>
práticas de uso de cookies. Recomendamos que você revisite esta seção <br/>
regularmente. <br/>
4. ARMAZENAMENTO E SEGURANÇA DOS DADOS <br/>
No Lumera 3D, adotamos medidas técnicas e organizacionais para garantir que <br/>
seus dados pessoais sejam armazenados de forma segura e protegida contra <br/>
acessos não autorizados, alterações, divulgações ou destruições. Esta seção <br/>
explica como tratamos o armazenamento e a segurança das suas informações. <br/>
4.1. Local de Armazenamento <br/>
Os dados coletados são armazenados em servidores seguros localizados no Brasil <br/>
ou em outros países que possuam níveis adequados de proteção de dados, <br/>
conforme previsto pela Lei Geral de Proteção de Dados Pessoais (LGPD). <br/>
Utilizamos provedores de serviços confiáveis, que seguem práticas rigorosas de <br/>
segurança. <br/>
4.2. Período de Retenção <br/>
Seus dados pessoais serão armazenados apenas pelo tempo necessário para <br/>
cumprir as finalidades descritas nesta Política de Privacidade ou para atender <br/>
exigências legais e regulatórias. <br/>
• Dados de cadastro: Retidos enquanto sua conta estiver ativa. <br/>
• Dados de pagamento: Mantidos conforme exigido por leis fiscais e <br/>
contábeis. <br/>
• Dados de navegação: Utilizados por períodos específicos para fins <br/>
analíticos, após o que são anonimizados ou excluídos. <br/>
4.3. Medidas de Segurança Adotadas <br/>
Para proteger suas informações, implementamos práticas de segurança como: <br/>
• Criptografia de Dados: Informações sensíveis, como dados de login e <br/>
pagamento, são transmitidas de forma criptografada (SSL/TLS). <br/>
• Controle de Acesso: Apenas funcionários autorizados têm acesso aos <br/>
dados, e mesmo assim, apenas na medida necessária para cumprir suas <br/>
funções. <br/>
• Monitoramento Contínuo: Realizamos auditorias regulares e <br/>
monitoramento para identificar e corrigir possíveis vulnerabilidades. <br/>
4.4. Gerenciamento de Incidentes de Segurança <br/>
Em caso de qualquer incidente de segurança que comprometa os seus dados, <br/>
tomaremos as seguintes medidas: <br/>
1. Notificação às autoridades competentes, conforme exigido pela LGPD. <br/>
2. Comunicação direta com os usuários afetados, explicando o ocorrido e as <br/>
medidas tomadas. <br/>
4.5. Responsabilidade do Usuário <br/>
Embora adotemos práticas rigorosas de segurança, também é essencial que você <br/>
tome medidas para proteger suas informações: <br/>
• Não compartilhe sua senha com terceiros. <br/>
• Use senhas fortes e seguras. <br/>
• Fique atento a possíveis e-mails de phishing ou atividades suspeitas em <br/>
sua conta. <br/>
4.6. Exclusão dos Dados <br/>
Você pode solicitar a exclusão dos seus dados pessoais a qualquer momento, <br/>
enviando uma solicitação para contato@lumera3d.com. Dados que devem ser <br/>
retidos por exigências legais ou para proteção de direitos legais não serão <br/>
excluídos até o término do período exigido. <br/>
5. DIREITOS DO USUÁRIO <br/>
Em conformidade com a Lei Geral de Proteção de Dados Pessoais (LGPD), você, <br/>
como titular dos dados pessoais, possui uma série de direitos que garantem maior <br/>
transparência, controle e segurança sobre as informações fornecidas ao Lumera <br/>
3D. Abaixo, detalhamos seus direitos e como exercê-los: <br/>
5.1. Direito de Confirmação e Acesso <br/>
Você tem o direito de confirmar se estamos tratando seus dados pessoais e <br/>
acessar as informações que mantemos sobre você. <br/>
Como exercer: <br/>
Envie um e-mail para contato@lumera3d.com solicitando a confirmação ou <br/>
acesso aos seus dados. <br/>
5.2. Direito de Retificação <br/>
Se os seus dados estiverem incorretos, incompletos ou desatualizados, você pode <br/>
solicitar a correção. <br/>
Como exercer: <br/>
Entre em contato pelo e-mail acima, especificando as informações que precisam <br/>
ser corrigidas. <br/>
5.3. Direito de Exclusão (Eliminação de Dados) <br/>
Você pode solicitar a exclusão de seus dados pessoais tratados com base no seu <br/>
consentimento, salvo em situações em que a retenção seja necessária para: <br/>
• Cumprimento de obrigações legais. <br/>
• Exercício regular de direitos em processos judiciais, administrativos ou <br/>
arbitrais. <br/>
5.4. Direito à Portabilidade <br/>
Você pode solicitar a transferência dos seus dados pessoais para outro fornecedor <br/>
de serviços ou produtos, respeitando as regulamentações da ANPD (Autoridade <br/>
Nacional de Proteção de Dados). <br/>
5.5. Direito à Informação sobre Compartilhamento <br/>
Você tem o direito de saber com quais terceiros seus dados foram compartilhados <br/>
e para qual finalidade. <br/>
5.6. Direito de Oposição <br/>
Se não concordar com o tratamento de seus dados com base em interesses <br/>
legítimos, você pode se opor, desde que apresente justificativa fundamentada. <br/>
5.7. Direito de Revogação do Consentimento <br/>
Você pode revogar o consentimento fornecido para o tratamento de seus dados a <br/>
qualquer momento. No entanto, a revogação pode impactar a continuidade de <br/>
alguns serviços. <br/>
5.8. Direito à Revisão de Decisões Automatizadas <br/>
Se alguma decisão que afete seus interesses for tomada com base em tratamento <br/>
automatizado de dados, você pode solicitar a revisão por uma pessoa. <br/>
Como exercer seus direitos? <br/>
Para exercer qualquer um dos direitos acima, envie sua solicitação para <br/>
contato@lumera3d.com, informando: <br/>
• Seu nome completo. <br/>
• O direito que deseja exercer. <br/>
• Detalhes adicionais que possam facilitar o atendimento à sua solicitação. <br/>
Prazos: <br/>
Nosso time analisará sua solicitação e responderá dentro do prazo de 15 dias, <br/>
conforme exigido pela LGPD. <br/>
Importante <br/>
Alguns direitos podem estar sujeitos a limitações, especialmente quando o <br/>
tratamento ou retenção dos dados for necessário para cumprir obrigações legais <br/>
ou regulatórias. <br/>
6. CONTATO <br/>
O Lumera 3D está comprometido em garantir a transparência no tratamento de <br/>
dados pessoais e em atender prontamente qualquer dúvida, solicitação ou <br/>
preocupação em relação à sua privacidade. Caso precise entrar em contato <br/>
conosco, utilize os canais abaixo: <br/>
Informações de Contato: <br/>
• E-mail: contato@lumera3d.com <br/>
Finalidades do Contato: <br/>
Você pode nos contatar para: <br/>
• Esclarecer dúvidas sobre esta Política de Privacidade ou sobre o <br/>
tratamento dos seus dados. <br/>
• Exercer os direitos previstos na LGPD (como acesso, retificação ou <br/>
exclusão de dados). <br/>
• Reportar possíveis violações de privacidade ou incidentes de segurança. <br/>
Horário de Atendimento: <br/>
Nosso time está disponível de segunda a sexta-feira, das 10h às 16h (horário de <br/>
Brasília), exceto feriados nacionais. <br/>

    </div>
      </div>
    </div>
 

 
  </>
  );
}

export default Privacidade;






