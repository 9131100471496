import { useNavigate } from "react-router-dom";
import logo from '../img/logo.png';
import { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { db, firebaseAuth } from '../utils/firebase-config';
function HomeLf(params) {
    

    const navigate = useNavigate();


    const [logado, setLogado] = useState(false);
    function master(params) {
        

        onAuthStateChanged(firebaseAuth, async (currentUser) => {
            if (currentUser) {setLogado(true);
            
         
        } else {
            navigate('/')
        }
          });
        }
    
         async function logOut(params) {
           await navigate('/')
            signOut(firebaseAuth); 
            setLogado(false);
          }

    useEffect(()=> {

        master();


    },[])

return (

<nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
<img src={logo} style={{height: '50px', marginLeft: '30px'}} />
 <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
     <span className="navbar-toggler-icon"><i class="material-icons opacity-10" style={{fontSize: '30px'}}>menu</i></span>
 </button>
 <div className="collapse navbar-collapse" id="navbarCollapse">
     <div className="navbar-nav ms-auto p-4 p-lg-0">
         <a style={{cursor: 'pointer'}} onClick={()=> navigate('/')} className="nav-item nav-link active">Home</a>
         <a style={{cursor: 'pointer'}} onClick={()=> navigate('/sobre')} className="nav-item nav-link">Sobre</a>
         <a style={{cursor: 'pointer'}}  className="nav-item nav-link">Como Usar</a>
 
         {logado ? 
                 <a className="nav-item nav-link"
                 style={{cursor: 'pointer'}}
                 onClick={()=> navigate('/main')}
                 >Projetos</a>
            :
            <a className="nav-item nav-link"
            style={{cursor: 'pointer'}}
            onClick={()=> navigate('/register')}
            >Criar conta</a>
            }

{logado ? 
              <a  className="nav-item nav-link sumir"
              onClick={()=> logOut()}
              >Deslogar</a>
              :
              <a  className="nav-item nav-link sumir"
              onClick={()=> navigate('/login')}
              >Login</a>
        }
     </div>
             {logado ? 
            <a href="" 
            onClick={()=> logOut()}
            style={{backgroundColor: 'rgb(70, 70, 214)'}} className="btn btn-primary py-3 px-lg-5 d-none d-lg-block">Deslogar<i className="fa fa-arrow-right ms-3"></i></a>
        :
<a href="" 
            onClick={()=> navigate('/login')}
            style={{backgroundColor: 'rgb(70, 70, 214)'}} className="btn btn-primary py-3 px-lg-5 d-none d-lg-block">Login<i className="fa fa-arrow-right ms-3"></i></a>
        }
 </div>
</nav>
)
}

export default HomeLf;
