import React, { useEffect, useState } from 'react';
import '../assets/css/material-dashboard.css'
import HomeLf from './HomeLf';
import { db, firebaseAuth } from '../utils/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import Background from 'three/examples/jsm/renderers/common/Background.js';
import { Center } from '@react-three/drei';

function Ordem(params) {

  const navigate = useNavigate();


  const [current, setCurrent] = useState('');
  const [dataA, setDataA] = useState([]);
  const [filtrados, setFiltrados] = useState([]);
  const [filtradosB, setFiltradosB] = useState([]);
  const [filtrados2, setFiltrados2] = useState([]);
  const [filtradosm, setFiltradosm] = useState([]);
  const [objetoTarget, setObjetoTarget] = useState();

  const [projetoId, setProjetoId] = useState('');

  const queryParameters = new URLSearchParams(window.location.search)
  const parametroId = queryParameters.get("prjid");
  const parametroId2 = queryParameters.get("ordem");


    console.log('MASTER MASTER', parametroId2);


var count01 = 0;


async function master(params) {

      

  var objMaster = [];
  var objMaster2 = [];

  setProjetoId(parametroId);


  onAuthStateChanged(firebaseAuth, async (currentUser) => {
    if (currentUser) {
      var usuarioLogado = currentUser.email;
      setCurrent(usuarioLogado);
    };
  });







        await getDocs(collection(db, "projetos"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs

                    .map((doc) => ({...doc.data(), id:doc.id }));
                    for (let i = 0; i < newData.length; i++) {
        
                   
                           objMaster2.push(newData[i]);
                      
                    }
               
                    var contas = [];
              
                      var arraya = [...Object.values(objMaster2)];
            
                    var criados = [];

               
                 
                    var emailatual = parametroId;
                    for (let i = 0; i < arraya.length; i++) {
                    
                        
                   if(arraya[i].idProjeto == emailatual){
                      criados.push(arraya[i]);
                   }

                      }

                      console.log('PAULAAA ',parametroId2);
                    console.log('criados', criados[0].ordens[parametroId2]);
                    setFiltradosB(criados[0].ordens[parametroId2]);
                      setFiltradosm(criados[0].ordens[parametroId2].mensagens)
                 setFiltrados(criados[0].ordens[parametroId2]);
                 setFiltrados2(criados[0].ordens[parametroId2].disciplinas)
                    
            })
        



}


async function editar(params) {
  navigate(`/editar?projeto=${params}`);
}





async function dell(params) {


  await updateDoc(doc(db, 'clientes', `${firebaseAuth.currentUser.email}:projetos`), {
    [params]: {
        nomeDoProjeto: 'nd',
      

    }
  
  });
  navigate(0);
}






useEffect(()=> {
  master();
},[])









    function enviarMensagem(params) {
      console.log('Click botão mensagem');
      navigate(`/enviarMensagens?prjid=${parametroId}&ordem=${parametroId2}`)
    }




function enviarPost01() {





  fetch('https://lumera-api.vercel.app/finalizarordem', {
    method: 'POST',
    body: JSON.stringify({
      email: firebaseAuth.currentUser.email,
      parametroId: parametroId,
      nomeOrdem: parametroId2,
      ordemDisciplina: [1,2,3,4,5,6],
      ordemPrioridade: [1,2,3,4,5,6]
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
     .then((response) => response.json())
     .then((data) => {
        console.log(data);
        // Handle data
     })
     .catch((err) => {
        console.log(err.message);
     });
}



    return (
        <div>


<HomeLf/>



<aside style={{top: '105px', overflow: 'hidden'}} class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark" id="sidenav-main">
    <div class="sidenav-header">
      <i class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
      <a class="navbar-brand m-0" target="_blank">

        <span class="ms-1 font-weight-bold text-white">Dashboard</span>
      </a>
    </div>
    <hr class="horizontal light mt-0 mb-2"/>
    <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link text-white active " >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">dashboard</i>
            </div>
            <span class="nav-link-text ms-1">Dashboard</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white "
             onClick={()=> navigate('/adicionar')}
          >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">table_view</i>
            </div>
            <span class="nav-link-text ms-1"
           
            >Adicionar Projeto</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white "
                onClick={()=> navigate('/colaborador')}
          >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">receipt_long</i>
            </div>
            <span class="nav-link-text ms-1">Colaborador</span>
          </a>
        </li>


      

    
      </ul>
    </div>
 
  </aside>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
      <div class="container-fluid py-1 px-3">
     
    
      </div>
    </nav>

    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl  position-absolute">
                <i class="material-icons opacity-10">weekend</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0">Mensagens</p>
              <h4 class="mb-0">{filtradosm.length}</h4>
      
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">

            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl position-absolute">
                <i class="material-icons opacity-10">person</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0">{!filtrados ? <>asd</> : filtrados.criador}</p>
                <h4 class="mb-0" style={{color: 'transparent'}}>.</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">
     
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl position-absolute">
                <i class="material-icons opacity-10">person</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">{Date().slice(15 ,28)}</p>
                <h4 class="mb-0" style={{color: 'transparent'}}>'</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">
       
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl position-absolute">
                <i class="material-icons opacity-10">weekend</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Server Status</p>
                <h4 class="mb-0">{!filtrados ? 'Manutenção' : 'OK'}</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">
    
            </div>
          </div>
        </div>
      </div>
      
      <div class="row mb-4"  style={{marginTop: '20px'}}>
        <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Detalhes da pendência</h6>
                  <p class="text-sm mb-0">
                 
            
                  </p>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
              
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
     
     





              <>


         





<div style={{width: '100%', height: '400px', padding: '20px'}}>
  <h3>{filtrados.nome}</h3>
  

  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Prioridade:</a> <a style={{color: 'red'}}>{filtrados.prioridade}</a>
  </div>
  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Status:</a><a style={{color: '#eead2d'}}> {filtrados.status} </a> 
  </div>

  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Criador:</a><a> {filtrados.criador} </a> 
  </div>



  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Disciplinas:</a>
  <>
                           
  {console.log('filtrados',filtrados.disciplinas)}


<> 
{filtrados2.map((data, index) => {

return <> 



<a> {data},</a>

</>
})}

</>



     
  
     


                    </>
  </div>
  <div style={{display: 'flex', marginTop: '120px'}}>
<button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
onClick={()=> navigate(-1)}
>
                    <i class="material-icons opacity-10" style={{marginRight: '5px'}}>view_in_ar </i> Voltar </button>         


        


                    <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
                      onClick={()=> navigate(`/editarpendencia?prjid=${parametroId}&ordem=${parametroId2}`)}
                    >
                    <i class="material-icons opacity-10" style={{marginRight: '5px'}}>edit </i> Editar </button> 

                    <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
                    onClick={()=> enviarMensagem()}
                    
                    >
                    <i class="material-icons opacity-10" style={{marginRight: '5px'}}>message </i> Mensagem </button> 

                    <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
                    onClick={()=> enviarPost01()}
                    
                    >
                    <i class="material-icons opacity-10" style={{marginRight: '5px'}}>close </i> Finalizar Pendência </button> 

</div>

</div>

                : 







                    </>

       


              </div>
            </div>
          </div>
        </div>
   



        <div class="row mb-4"  style={{marginTop: '20px'}}>
        <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Mensagens</h6>
                  <p class="text-sm mb-0">
                 
            
                  </p>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
              
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mensagens</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Disciplina</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Remetente</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                    </tr>
                  </thead>
                  <tbody>
              
      {console.log('FiltradosMM ',filtradosm)}




      <>
                                  {filtradosm.map((data, index) => {

return <> 


<tr>
<td>
  <div class="d-flex px-2 py-1">
    <div>

    </div>
    <div class="d-flex flex-column justify-content-center">
      <h6 class="mb-0 text-sm" style={{marginLeft: '50px'}}>{data.split('#####remetente:')[0]}</h6>
    </div>
  </div>
</td>
<td>
<div class="progress-wrapper mx-auto">
{console.log('ALL ALL', filtradosm)}
  <a>{data.split('#####remetente:')[1].split('####disciplinas')[1].replaceAll('####disciplina', '').replaceAll('  ', ', ').replace(',', '')}</a>

   
  
  </div>
</td>
<td class="align-middle text-center text-sm">
  <span class="text-xs font-weight-bold"> {data.split('#####remetente:')[1].split('####disciplinas')[0]} </span>
</td>
<td class="align-middle  text-center">
  <div class="progress-wrapper mx-auto">

  
  </div>
</td>
</tr>

</>
})}
                    </>


















                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
   
      </div>




      </div>
      <footer class="footer py-4  ">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
  
            </div>
     
          </div>
        </div>
      </footer>
    </div>
  </main>
  






        </div>
    )
}
export default Ordem;