



import { useNavigate } from 'react-router-dom';
import HomeLf from './HomeLf';



var runtime = 0;
function Termos() {


const navigate = useNavigate();









  return (
    <>
<HomeLf/>


  <h4 className="py-4 text-center"
      style={{color: '#06BBCC', fontSize: '30px', fontWeight: '800', marginTop: '125px'}}
    
   >TERMOS DE USO DO LUMERA 3D</h4>
    

      <div  >
      <div style={{marginBottom: "50px", paddingLeft: '10%', paddingRight: '10%', color: '#666', fontSize: '20px'}}>

    
    <div style={{textAlign: 'center', width: '100%'}}>

Data de Vigência: 02 de dezembro de 2024 <br/> <br></br>
Bem-vindo ao Lumera 3D! Estes Termos de Uso regulam o acesso e a utilização do <br/>
nosso site e serviços, oferecendo orientações claras sobre as responsabilidades e <br/>
direitos de nossos usuários. Ao acessar ou utilizar o Lumera 3D, você concorda <br/>
com os termos descritos neste documento. <br/>
Por favor, leia atentamente os Termos de Uso antes de prosseguir com a utilização <br/>
do site. Caso não concorde com qualquer parte destes termos, recomendamos <br/>
que não utilize nossos serviços. <br/>
Nosso objetivo é fornecer uma experiência eficiente e segura para a gestão de <br/>
projetos 3D, além de facilitar a colaboração entre equipes. Estes Termos de Uso <br/>
garantem que todos utilizem a plataforma de forma responsável e em <br/>
conformidade com as legislações aplicáveis, incluindo a Lei Geral de Proteção <br/>
de Dados Pessoais (LGPD). <br/>
Se você tiver dúvidas ou precisar de mais informações, entre em contato conosco <br/>
através do e-mail contato@lumera3d.com. <br/>
Agradecemos por escolher o Lumera 3D como seu parceiro em projetos 3D. <br/> <br/>
1. LICENÇA DE USO <br/> <br/>
O Lumera 3D concede aos usuários uma licença limitada, não exclusiva, <br/>
intransferível e revogável para acessar e utilizar a plataforma e seus serviços, de <br/>
acordo com os Termos de Uso estabelecidos neste documento. <br/> <br/>
1.1. Permissões <br/> <br/>
Os usuários têm permissão para: <br/>
• Acessar o site e utilizar as funcionalidades disponibilizadas, como upload e <br/>
gerenciamento de modelos 3D. <br/>
• Visualizar, fazer download e interagir com o conteúdo disponibilizado, <br/>
desde que para fins pessoais ou profissionais diretamente relacionados ao <br/>
uso autorizado da plataforma. <br/> <br/>
1.2. Restrições <br/> <br/>
Os usuários comprometem-se a não: <br/>
• Reproduzir, distribuir, modificar, criar obras derivadas, vender, alugar ou <br/>
explorar comercialmente qualquer parte do site ou seus conteúdos sem <br/>
autorização prévia. <br/>
• Utilizar o site para fins ilegais, fraudulentos ou que violem direitos de <br/>
terceiros. <br/>
• Tentar acessar áreas protegidas do site sem a devida autorização. <br/>
• Interferir no funcionamento da plataforma ou introduzir códigos <br/>
maliciosos, como vírus, malwares ou bots que prejudiquem a experiência <br/>
de outros usuários. <br/>
• Utilizar ferramentas automatizadas para extrair dados da plataforma <br/>
(scraping) sem permissão explícita. <br/> <br/>
1.3. Propriedade Intelectual <br/> <br/>
• Todos os direitos de propriedade intelectual associados ao Lumera 3D e <br/>
seus conteúdos são de propriedade exclusiva do Lumera 3D ou de seus <br/>
licenciadores. <br/>
• O uso da plataforma não concede ao usuário qualquer direito de <br/>
propriedade sobre marcas, logotipos, conteúdo ou funcionalidades do site. <br/> <br/>
1.4. Revogação da Licença <br/> <br/>
O Lumera 3D reserva-se o direito de revogar a licença de uso a qualquer <br/>
momento, sem aviso prévio, caso o usuário viole os Termos de Uso ou utilize a <br/>
plataforma de maneira inadequada. <br/> <br/>
1.5. Atualizações e Modificações <br/> <br/>
A plataforma pode ser atualizada ou modificada periodicamente para melhorar os <br/>
serviços ou garantir conformidade com novas regulamentações. Esses ajustes <br/>
podem incluir mudanças na licença de uso, sendo responsabilidade do usuário <br/>
revisar os Termos de Uso regularmente. <br/> <br/>
2. TERMOS PARA UPLOAD DE MODELOS 3D <br/> <br/>
O Lumera 3D oferece aos usuários a possibilidade de fazer upload de modelos 3D <br/>
para facilitar o gerenciamento de projetos e a colaboração entre equipes. Esta <br/>
seção detalha as condições e responsabilidades relacionadas ao envio de <br/>
arquivos para a plataforma. <br/> <br/>
2.1. Responsabilidade pelo Conteúdo <br/> <br/>
• O usuário declara ser o titular dos direitos autorais e de propriedade <br/>
intelectual sobre os modelos 3D enviados ou possuir autorização expressa <br/>
para utilizá-los e compartilhá-los. <br/>
• O usuário é o único responsável pelos modelos enviados, incluindo a <br/>
precisão das informações, eventuais violações de direitos de terceiros e <br/>
quaisquer implicações legais associadas. <br/> <br/>
2.2. Proibição de Conteúdo Inadequado <br/> <br/>
Não é permitido o upload de conteúdo que: <br/>
• Contenha materiais ilegais, ofensivos, difamatórios ou pornográficos. <br/>
• Viole direitos autorais, patentes, marcas registradas ou outros direitos de <br/>
propriedade intelectual de terceiros. <br/>
• Inclua vírus, malwares ou qualquer código que possa comprometer o <br/>
funcionamento da plataforma ou dos dispositivos de outros usuários. <br/> <br/>
2.3. Licença sobre os Arquivos Enviados <br/> <br/>
Ao fazer upload de um modelo 3D, o usuário concede ao Lumera 3D uma licença <br/>
limitada, não exclusiva, e isenta de royalties para: <br/>
• Processar, armazenar e exibir os arquivos na plataforma, conforme <br/>
necessário para fornecer os serviços contratados. <br/>
• Compartilhar os arquivos com outros membros da equipe ou terceiros <br/>
autorizados pelo próprio usuário. <br/> <br/>
2.4. Privacidade dos Arquivos <br/> <br/>
• Os modelos enviados serão tratados como confidenciais e não serão <br/>
acessados ou divulgados pelo Lumera 3D, salvo com o consentimento do <br/>
usuário ou para cumprimento de obrigações legais. <br/>
• O Lumera 3D implementa medidas de segurança para proteger os arquivos <br/>
enviados, mas não se responsabiliza por acessos não autorizados <br/>
decorrentes de falhas externas ou negligência do usuário. <br/> <br/>
2.5. Armazenamento e Exclusão <br/> <br/>
• Os arquivos enviados serão armazenados pela plataforma enquanto forem <br/>
necessários para os serviços ou conforme o período estipulado pelo <br/>
contrato com o usuário. <br/>
• O usuário pode solicitar a exclusão dos arquivos a qualquer momento, <br/>
salvo em situações que exijam retenção por motivos legais ou regulatórios. <br/>
2.6. Violações <br/>
Caso um modelo enviado viole os Termos de Uso, o Lumera 3D reserva-se o <br/> <br/>
direito de: <br/> <br/>
• Remover o arquivo sem aviso prévio. <br/>
• Suspender ou encerrar a conta do usuário, em caso de reincidência ou <br/>
violações graves. <br/>
• Reportar atividades ilegais às autoridades competentes, quando <br/>
necessário. <br/> <br/>
2.7. Isenção de Responsabilidade <br/> <br/>
O Lumera 3D não é responsável pelo uso indevido dos modelos 3D por outros <br/>
usuários ou terceiros. A responsabilidade pelo compartilhamento e uso adequado <br/>
dos arquivos é do próprio usuário. <br/> <br/>
3. POLÍTICA DE CANCELAMENTO E REEMBOLSO <br/> <br/>
O Lumera 3D busca oferecer a melhor experiência aos seus usuários e, para isso, <br/>
estabelece uma Política de Cancelamento e Reembolso clara e transparente. Leia <br/>
atentamente esta seção para entender as condições aplicáveis. <br/> <br/>
3.1. Cancelamento de Serviços <br/> <br/>
• O usuário pode solicitar o cancelamento de serviços contratados <br/>
diretamente na plataforma ou por meio do e-mail <br/>
contato@lumera3d.com. <br/>
• O cancelamento será processado em até 7 dias úteis a partir da <br/>
solicitação. <br/>
3.2. Período de Arrependimento (Conforme o Código de Defesa do <br/> <br/>
Consumidor) <br/> <br/>
• De acordo com o Art. 49 do Código de Defesa do Consumidor, o usuário <br/>
tem até 7 dias corridos após a contratação ou pagamento para solicitar o <br/>
cancelamento e o reembolso integral, desde que o serviço não tenha sido <br/>
totalmente utilizado. <br/> <br/>
3.3. Reembolsos <br/> <br/>
• Os reembolsos serão processados dentro de 10 dias úteis após a <br/>
aprovação da solicitação. <br/>
• O valor será reembolsado para a mesma forma de pagamento utilizada <br/>
pelo usuário no ato da compra. <br/> <br/>
3.4. Condições para Reembolso <br/> <br/>
O reembolso será aplicável nas seguintes situações: <br/>
• Solicitação dentro do período de arrependimento. <br/>
• Erro ou falha técnica comprovada na prestação do serviço. <br/> <br/>
3.5. Situações em que o Reembolso Não é Aplicável <br/> <br/>
• Serviços já utilizados ou entregues, como downloads de arquivos ou <br/>
upload de modelos processados. <br/>
• Cancelamentos fora do período de arrependimento sem motivo justificado. <br/>
• Violações dos Termos de Uso por parte do usuário. <br/> <br/>
3.6. Procedimento para Cancelamento e Reembolso <br/> <br/>
1. O usuário deve entrar em contato pelo e-mail contato@lumera3d.com <br/>
com as seguintes informações: <br/>
o Nome completo. <br/>
o Serviço contratado. <br/>
o Motivo do cancelamento ou reembolso. <br/>
2. O Lumera 3D analisará a solicitação e enviará um retorno em até 5 dias <br/>
úteis. <br/> <br/>
3.7. Cancelamento pela Plataforma <br/> <br/>
O Lumera 3D reserva-se o direito de cancelar unilateralmente serviços <br/>
contratados pelo usuário, nas seguintes situações: <br/>
• Violação dos Termos de Uso ou Política de Privacidade. <br/>
• Atividades fraudulentas ou ilegais detectadas na conta do usuário. <br/>
• Inadimplência no pagamento. <br/>
Nesses casos, o usuário será notificado com antecedência e orientado sobre os <br/>
próximos passos. <br/> <br/>
4. AVISO LEGAL <br/> <br/>
O presente Aviso Legal rege o uso do site Lumera 3D e estabelece as limitações <br/>
de responsabilidade e os termos gerais que devem ser observados pelos usuários. <br/>
Ao acessar a plataforma, você concorda com as condições descritas a seguir. <br/> <br/>
4.1. Informações Gerais <br/> <br/>
• O Lumera 3D oferece ferramentas para o gerenciamento de projetos 3D e <br/>
armazenamento de modelos 3D. Embora nos esforcemos para garantir a <br/>
precisão e funcionalidade dos serviços, não garantimos que o site estará <br/>
isento de erros ou interrupções. <br/>
• O conteúdo do site, incluindo textos, imagens e funcionalidades, é <br/>
fornecido "no estado em que se encontra" e está sujeito a alterações sem <br/>
aviso prévio. <br/> <br/>
4.2. Limitação de Responsabilidade <br/> <br/>
• O Lumera 3D não se responsabiliza por danos diretos, indiretos, <br/>
incidentais ou consequenciais decorrentes do uso ou incapacidade de uso <br/>
da plataforma, incluindo perda de dados, interrupções de serviço ou danos <br/>
causados por erros técnicos. <br/>
• Não garantimos que os modelos 3D enviados pelos usuários estejam livres <br/>
de falhas ou sejam adequados para fins específicos. A responsabilidade <br/>
pelo uso adequado dos arquivos enviados ou recebidos recai <br/>
exclusivamente sobre os usuários. <br/> <br/>
4.3. Responsabilidade do Usuário <br/> <br/>
• É de responsabilidade do usuário garantir que o uso da plataforma está em <br/>
conformidade com as leis aplicáveis e os Termos de Uso. <br/>
• O usuário deve manter backup de seus arquivos e não deve depender <br/>
exclusivamente da plataforma para armazenamento de informações <br/>
críticas. <br/> <br/>
4.4. Conteúdo de Terceiros <br/> <br/>
• O site pode conter links ou referências a conteúdos de terceiros. O Lumera <br/>
3D não se responsabiliza pela precisão, qualidade ou disponibilidade <br/>
desses conteúdos, nem pelo uso que você fizer dessas informações. <br/> <br/>
4.5. Segurança <br/> <br/>
Embora empreguemos medidas de segurança para proteger os dados e <br/>
informações do usuário, não podemos garantir proteção absoluta contra ataques <br/>
cibernéticos ou falhas imprevistas. Recomendamos que os usuários adotem <br/>
práticas seguras, como o uso de senhas fortes e atualizações regulares de seus <br/>
dispositivos. <br/> <br/>
4.6. Atualizações do Aviso Legal <br/> <br/>
Este Aviso Legal pode ser alterado periodicamente para refletir atualizações no <br/>
serviço ou mudanças legais. É responsabilidade do usuário revisar este <br/>
documento regularmente para manter-se informado. <br/> <br/>
4.7. Jurisdição <br/> <br/>
Este Aviso Legal é regido pelas leis da República Federativa do Brasil. Fica eleito o <br/>
foro da comarca de Canoas, Rio Grande do Sul, para resolver quaisquer questões <br/>
relacionadas ao seu conteúdo. <br/> <br/>
5. DISPOSIÇÕES GERAIS <br/> <br/>
As disposições gerais deste documento complementam e reforçam as diretrizes <br/>
apresentadas nos Termos de Uso, assegurando que todos os aspectos legais e <br/>
operacionais da utilização da plataforma Lumera 3D sejam devidamente <br/>
compreendidos e aplicados. <br/> <br/>
5.1. Integralidade do Contrato <br/> <br/>
• Estes Termos de Uso, juntamente com a Política de Privacidade, <br/>
representam o acordo integral entre o usuário e o Lumera 3D, substituindo <br/>
quaisquer entendimentos ou acordos anteriores. <br/>
• Caso alguma disposição destes termos seja considerada inválida ou <br/>
inexequível, as demais permanecerão em pleno vigor e efeito. <br/> <br/>
5.2. Alterações nos Termos <br/> <br/>
• O Lumera 3D reserva-se o direito de alterar, a qualquer momento, os <br/>
Termos de Uso e documentos relacionados para refletir atualizações nos <br/>
serviços ou mudanças legais. <br/>
• Alterações substanciais serão comunicadas aos usuários com <br/>
antecedência, por meio do site ou de e-mail cadastrado. <br/>
• O uso continuado da plataforma após a atualização dos termos implica na <br/>
aceitação das mudanças. <br/> <br/>
5.3. Relação entre as Partes <br/> <br/>
• Estes Termos de Uso não criam qualquer relação de sociedade, parceria, <br/>
representação ou vínculo empregatício entre o usuário e o Lumera 3D. <br/> <br/>
5.4. Legislação Aplicável <br/> <br/>
• Estes Termos de Uso são regidos pelas leis da República Federativa do <br/>
Brasil e devem ser interpretados de acordo com as mesmas. <br/> <br/>
5.5. Renúncia <br/> <br/>
• A falha do Lumera 3D em exigir o cumprimento de qualquer disposição <br/>
destes termos não será considerada uma renúncia de direitos, podendo <br/>
exercer tais direitos a qualquer momento. <br/> <br/>
5.6. Contato <br/> <br/>
• Em caso de dúvidas ou necessidade de esclarecimentos adicionais, entre <br/>
em contato conosco por meio do e-mail contato@lumera3d.com. <br/>







    </div>
      </div>
    </div>
 

 
  </>
  );
}

export default Termos;






