import React, { useEffect, useState } from 'react';
import '../assets/css/material-dashboard.css'
import HomeLf from './HomeLf';
import { db, firebaseAuth } from '../utils/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import Background from 'three/examples/jsm/renderers/common/Background.js';
import { Center } from '@react-three/drei';

function OrdensDeServico(params) {

  const navigate = useNavigate();


  const [current, setCurrent] = useState('');
  const [dataA, setDataA] = useState([]);
  const [filtrados, setFiltrados] = useState([]);
  const [filtradosB, setFiltradosB] = useState([]);
  const [objetoTarget, setObjetoTarget] = useState();
  const [dataId, setDataId] = useState();


var count01 = 0;
const queryParameters = new URLSearchParams(window.location.search)
const parametroId = queryParameters.get("prjid")

async function master(params) {

      

  var objMaster = [];
  var objMaster2 = [];

   


  onAuthStateChanged(firebaseAuth, async (currentUser) => {
    if (currentUser) {
      var usuarioLogado = currentUser.email;
      setCurrent(usuarioLogado);
    };
  });


  await fetch('https://lumera-api.vercel.app/consultaOrdem', {
    method: 'POST',
    body: JSON.stringify({
      parametroId: parametroId,

    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
     .then((response) => response.json())
     .then((data) => {
        console.log(data);

        var criados = data;
        var tradd = [];
        setFiltradosB(criados);


        tradd.push(criados.ordens);

        tradd = tradd[0];
        console.log('SSSSSS ',tradd);
        var masterTrad = [];
      for (let i = 0; i < Object.keys(criados.ordens).length; i++) {
            console.log(tradd[Object.keys(criados.ordens)[i]]);
            masterTrad.push(tradd[Object.keys(criados.ordens)[i]]);
      }
            var filtrar = [];
        for (let i = 0; i < masterTrad.length; i++) {
          if(masterTrad[i].status == 'Pendente'){
            filtrar.push(masterTrad[i]);
          }
          
        }
        console.log('MASTER MASTER', masterTrad);
      setFiltrados(filtrar);
              











     })
     .catch((err) => {
        console.log(err.message);
     });



        



}


async function editar(params) {
  navigate(`/editar?projeto=${params}`);
}





async function dell(params) {


  await updateDoc(doc(db, 'clientes', `${firebaseAuth.currentUser.email}:projetos`), {
    [params]: {
        nomeDoProjeto: 'nd',
      

    }
  
  });
  navigate(0);
}






useEffect(()=> {
  master();
},[])

















    return (
        <div>


<HomeLf/>



<aside style={{top: '105px', overflow: 'hidden'}} class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark" id="sidenav-main">
    <div class="sidenav-header">
      <i class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
      <a class="navbar-brand m-0" target="_blank">

        <span class="ms-1 font-weight-bold text-white">Dashboard</span>
      </a>
    </div>
    <hr class="horizontal light mt-0 mb-2"/>
    <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link text-white active " >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">dashboard</i>
            </div>
            <span class="nav-link-text ms-1">Dashboard</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white "
             onClick={()=> navigate('/adicionar')}
          >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">table_view</i>
            </div>
            <span class="nav-link-text ms-1"
           
            >Adicionar Projeto</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white "
                onClick={()=> navigate('/colaborador')}
          >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">receipt_long</i>
            </div>
            <span class="nav-link-text ms-1">Colaborador</span>
          </a>
        </li>


      

    
      </ul>
    </div>
 
  </aside>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
      <div class="container-fluid py-1 px-3">
     
    
      </div>
    </nav>

    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl  position-absolute">
                <i class="material-icons opacity-10">weekend</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0">Pendências criadas</p>
                <h4 class="mb-0">{filtrados.length}</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">

            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl position-absolute">
                <i class="material-icons opacity-10">person</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0">{!filtrados[0] ? <></> : filtrados[0].criador}</p>
                <h4 class="mb-0" style={{color: 'transparent'}}>.</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">
     
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl position-absolute">
                <i class="material-icons opacity-10">person</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">{Date().slice(15 ,28)}</p>
                <h4 class="mb-0" style={{color: 'transparent'}}>'</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">
       
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl position-absolute">
                <i class="material-icons opacity-10">weekend</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Server Status</p>
                <h4 class="mb-0">{!filtrados ? 'Manutenção' : 'OK'}</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">
    
            </div>
          </div>
        </div>
      </div>
      
      <div class="row mb-4"  style={{marginTop: '20px'}}>
        <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Pendências</h6>
                  <p class="text-sm mb-0">
                 
            
                  </p>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
             
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
     
     





              <>

                                  {filtrados.map((data, index) => {

return <> 

{

 filtrados.length > 0 ? 

<div style={{width: '100%', height: '400px', padding: '20px'}}>
  <h3>{data.nome}</h3>
  

  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Prioridade:</a> <a style={{color: 'red'}}>{data.prioridade}</a>
  </div>
  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Status:</a><a style={{color: '#eead2d'}}> {data.status} </a> 
  </div>
  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Criador:</a><a> {data.criador} </a> 
  </div>




  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Disciplinas:</a>
  <>
                           
  <a> 
     
     
     {data.disciplinas.map((data, index) => {

      return <> 
      
      
      
      <a> {data},</a>
      
      </>
      })}
     
     

  </a>



                    </>
  </div>
  <div style={{display: 'flex', marginTop: '120px'}}>
<button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
onClick={()=> navigate(-1)}
>
                    <i class="material-icons opacity-10" style={{marginRight: '5px'}}>view_in_ar </i> Voltar </button>         


        


                    <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
                    onClick={()=> navigate(`/ordem?prjid=${parametroId}&ordem=${data.nome}`)}
                    
                    
                    >
                    <i class="material-icons opacity-10" style={{marginRight: '5px'}}>edit </i> Detalhes </button> 
</div>

</div>

                : <a style={{padding: '20px', fontSize: '20px'}}>Você não tem pendências!</a>
}





</>
})}
                    </>

       


              </div>
            </div>
          </div>
        </div>
   







      </div>
      <footer class="footer py-4  ">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
  
            </div>
     
          </div>
        </div>
      </footer>
    </div>
  </main>






        </div>
    )
}
export default OrdensDeServico;