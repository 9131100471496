import React, { useEffect, useState } from 'react';
import AppM from '../AppM';
import HomeLf from './HomeLf';
import axios from 'axios';
import Viewer from '../Viewer';
import { useNavigate } from 'react-router-dom';


function Projeto() {

  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const type = queryParameters.get("urn");
  const type2 = queryParameters.get("token");

  const [pronto, setPronto] = useState(1);

  async function fetchMaster(params) {
  


    console.log('asdasd');

    axios({
      method: 'get',
      url: `https://idx7.com.br/api/models/:${type}/status`,

    })
      .then(function (response) {

        if(response.data.progress == 'complete'){
          setPronto(3);
        } else {
          setPronto(2);
    
          setTimeout(()=> {
            navigate(-1);
          },3000)
        }

      });




}



useEffect(()=> {
 fetchMaster();
},[])


return ( <>


<HomeLf/>

{pronto == 3 ?
<> 

{
        window.scroll({
          top: 11100,
          left: 100,
          behavior: "smooth",
        })
}
<Viewer
                        runtime={{ accessToken: type2 }}
                        urn={type}
                      
                    />
</> : <> {pronto == 1 ?  <h2>Carregando...</h2> :<div  style={{position: 'absolute', textAlign: 'center', width: '100%', height: '100%', display: 'column', alignContent: 'center'}}> <h2>Convertendo modelo, por favor aguarde!</h2> </div>}  </>
}




</>
); }
export default Projeto;