import React, { useEffect, useState } from 'react';
import '../assets/css/material-dashboard.css'
import HomeLf from './HomeLf';
import { db, firebaseAuth } from '../utils/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';

function CriarColaborador(params) {

  const navigate = useNavigate();


  const [current, setCurrent] = useState('');
  const [dataA, setDataA] = useState([]);
  const [filtrados, setFiltrados] = useState([]);
  const [filtradosB, setFiltradosB] = useState(0);
  const [filtradosC, setFiltradosC] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [disciplina, setDisciplina] = useState('');
  const [ordem, setOrdem] = useState('');

  const [mapeA, setMapeA] = useState([]);
  const [countMp, setCountMp] = useState(1);
  const [countMpC, setCountMpC] = useState(0);

var count01 = 0;






const queryParameters = new URLSearchParams(window.location.search)
const parametroId = queryParameters.get("prjid");
const parametroId2 = queryParameters.get("ordem");





async function master(params) {

      

  var objMaster = [];
  var objMaster2 = [];

   


  onAuthStateChanged(firebaseAuth, async (currentUser) => {
    if (currentUser) {
      var usuarioLogado = currentUser.email;
      setCurrent(usuarioLogado);
    };
  });







        await getDocs(collection(db, "projetos"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs

                    .map((doc) => ({...doc.data(), id:doc.id }));
                    for (let i = 0; i < newData.length; i++) {
        
                   
                           objMaster2.push(newData[i]);
                      
                    }

                    var contas = [];
              
                      var arraya = [...Object.values(objMaster2)];
             
                    var criados = [];
                    var filter01 = [];
                 
                    var emailatual = firebaseAuth.currentUser.email;
                    for (let i = 0; i < arraya.length; i++) {
                    
                        
                   if(arraya[i].id == parametroId){
                      criados.push(arraya[i]);
                   }

                
              
                      }
                      setMapeA(criados[0].disciplinas)
                     //   setFiltradosB(criados[0].ordens[parametroId2].mensagens.length);
                    //    setFiltradosC(criados[0].criador)
                 //   setFiltrados(criados[0].ordens[parametroId2].disciplinas);
                    
            })
        



}










function enviarPost01() {



    var hierarquiaT = document.querySelector('.hierarquia').value;
    var emailT = document.querySelector('.emailmaster').value;
    var disciplinaT = document.querySelector('.disciplina').value;


  fetch('https://lumera-api.vercel.app/adicionarColaborador', {
    method: 'POST',
    body: JSON.stringify({
      email: emailT,
      hierarquia: hierarquiaT,
      disciplina: disciplinaT,
      id: parametroId
   
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
     .then((response) => response.json())
     .then((data) => {
        console.log(data);
        // Handle data
     })
     .catch((err) => {
        console.log(err.message);
     });


    
}






useEffect(()=> {
  master();
},[])











    return (
        <div>


<HomeLf/>



<aside style={{top: '105px', overflow: 'hidden'}} class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark" id="sidenav-main">
    <div class="sidenav-header">
      <i class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
      <a class="navbar-brand m-0" target="_blank">

        <span class="ms-1 font-weight-bold text-white">Dashboard</span>
      </a>
    </div>
    <hr class="horizontal light mt-0 mb-2"/>
    <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link text-white active " >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">dashboard</i>
            </div>
            <span class="nav-link-text ms-1">Dashboard</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white "
             onClick={()=> navigate('/adicionar')}
          >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">table_view</i>
            </div>
            <span class="nav-link-text ms-1"
           
            >Adicionar Projeto</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white "
                onClick={()=> navigate('/colaborador')}
          >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">receipt_long</i>
            </div>
            <span class="nav-link-text ms-1">Colaborador</span>
          </a>
        </li>


      

    
      </ul>
    </div>
 
  </aside>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
      <div class="container-fluid py-1 px-3">
     
    
      </div>
    </nav>

    <div class="container-fluid py-4">

      
      <div class="row mb-4"  style={{marginTop: '20px'}}>
        <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Adicionar Colaborador</h6>
                  <p class="text-sm mb-0">
                 
            
                  </p>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
             
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Hierarquia</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Disciplina</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Adicionar</th>
                    </tr>
                  </thead>
                  <tbody> 
              
      

<>

 <> 

<tr>
<td>
  <div class="d-flex px-2 py-1">
    <div>

    </div>
    <div class="d-flex flex-column justify-content-center">
      <h6 class="mb-0 text-sm" style={{height: '50px', width: '250px', padding: '10px', border: 'solid black 1px'}}><input
      className="emailmaster"
      onChange={(e)=> setMensagem(e.target.value)}
      placeholder='Email'/> </h6>
    </div>
  </div>
</td>
<td>
  <div class="avatar-group mt-2">


                        <select className="hierarquia" style={{border: 'solid 1px ', borderRadius: '10px', padding: '10px 10px'}}>
  
  


     
                        <option value='Administrador'>Administrador</option>
                        <option value='Gerente '>Gerente </option>
                        <option value='Engenheiro'>Engenheiro</option>
                        <option value='Prestador de serviços'>Prestador de serviços</option>



</select>
  
     



  </div>
</td>
<td class="align-middle text-center text-sm">

    
  <select className="disciplina" style={{border: 'solid 1px ', borderRadius: '10px', padding: '10px 10px'}}>
  
  



  {mapeA.map((data, index) => {

return <> 
                     <option value={data}>{data}</option>

                           </>
})}


</select>
  
</td>
<td class="align-middle  text-center">
  <div class="progress-wrapper mx-auto">
    <a style={{ cursor: 'pointer', border: '2px black solid', borderRadius: '10px', padding: '10px'}}
    onClick={()=> enviarPost01()}
    > Adicionar </a>
  
  </div>
</td>
</tr>






</>

                    </>







                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
   
      </div>
      <footer class="footer py-4  ">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
  
            </div>
     
          </div>
        </div>
      </footer>
    </div>
  </main>






        </div>
    )
}
export default CriarColaborador;