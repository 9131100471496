import logo from './img/logo.png';
import carousel1 from './img/carousel-1.jpg';
import carousel2 from './img/carousel-2.jpg';
import cat1 from './img/cat-1.jpg';
import cat2 from './img/cat-2.jpg';
import cat3 from './img/cat-3.jpg';
import cat4 from './img/cat-4.jpg';

import homepageimg from './img/master.png';

import testimonial1 from './img/testimonial-1.jpg';
import testimonial2 from './img/testimonial-2.jpg';
import testimonial3 from './img/testimonial-3.jpg';
import testimonial4 from './img/testimonial-4.jpg';


import about from './img/about.jpg';

import './App.css';
import Render3d from './components/Render3d';
import { Navigate, useNavigate } from 'react-router-dom';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { db, firebaseAuth } from './utils/firebase-config';
import { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';



function Home() {
    const navigate = useNavigate();





    const [logado, setLogado] = useState(false);




    function master(params) {
        

    onAuthStateChanged(firebaseAuth, async (currentUser) => {
        if (currentUser) {setLogado(true);
        
     
    }
      });
    }

      function logOut(params) {
        signOut(firebaseAuth); 
        setLogado(false);
      }


useEffect(()=> {
master();

}, []);





async function testRegister() {
    await updateDoc(doc(db, 'clientes', `${'althertechadm@gmail.com'}`), {

        nome: {maria: 222, joão: 333},
        item01: {nome: 'item01', descricao: 'item01 01 01'},
        item02: {nome: 'item01', descricao: 'item01 01 01'},
        item03: {nome: 'MASTER', descricao: 'MASTER'}
     
    
    
      });
}




  return (
    <div className="App">
      
      {
        // APP MASTER
      }

      

    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
       <img src={logo} style={{height: '50px', marginLeft: '30px'}} />
        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"><i class="material-icons opacity-10" style={{fontSize: '30px'}}>menu</i></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
                <a
                style={{cursor: 'pointer'}}
                onClick={()=> navigate('/')} className="nav-item nav-link active">Home</a>
                <a 
                style={{cursor: 'pointer'}}
          onClick={()=> navigate('/sobre')} 
                className="nav-item nav-link">Sobre</a>
                <a  className="nav-item nav-link">Como Usar</a>
             
            
                {logado ? 
                 <a className="nav-item nav-link"
                 style={{cursor: 'pointer'}}
                 onClick={()=> navigate('/main')}
                 >Projetos</a>
            :
            <>
            <a className="nav-item nav-link"
            style={{cursor: 'pointer'}}
            onClick={()=> navigate('/register')}
            >Criar conta</a>
        
          
            </>
            }
                   {logado ? 
              <a  className="nav-item nav-link sumir"
              onClick={()=> logOut()}
              >Deslogar</a>
              :
              <a  className="nav-item nav-link sumir"
              onClick={()=> navigate('/login')}
              >Login</a>
        }
                
            </div>
            {logado ? 
            <a href="" 
            onClick={()=> logOut()}
            style={{backgroundColor: 'rgb(70, 70, 214)'}} className="btn btn-primary py-3 px-lg-5 d-none d-lg-block">Deslogar<i className="fa fa-arrow-right ms-3"></i></a>
        :
<a href="" 
            onClick={()=> navigate('/login')}
            style={{backgroundColor: 'rgb(70, 70, 214)'}} className="btn btn-primary py-3 px-lg-5 d-none d-lg-block">Login<i className="fa fa-arrow-right ms-3"></i></a>
        }
            
        </div>
    </nav>

    <div className="container-fluid p-0 mb-5" >
        
        <div className="header-carousel" style={{ width: '100%', height: '100%', objectFit: 'cover'}}>
            
            <div className="owl-carousel-item position-relative">
            <img src={homepageimg} alt="" style={{ height: '430px', width: '100%'}} />

                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{background: 'rgba(24, 29, 56, .7)', pointerEvents: 'none'}}>
                    <div className="container">
                        <div style={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'start'}}>
                            <div>
                                <h5 className="text-primary text-uppercase mb-3 animated slideInDown"
                                style={{textAlign: 'start'}}
                                
                                >Gerenciamente de projetos</h5>
                                <h1 className="display-3 text-white animated slideInDown">Planta 3d no navegador</h1>
                                <p className="fs-5 text-white mb-4 pb-2"
                                style={{textAlign: 'start'}}
                                >Gerencie seus projetos visualizando sua planta em 3 dimensões.</p>
                                <a href="" className="btn btn-primary py-md-2 px-md-5 me-3 animated slideInLeft"
                                style={{float: 'left'}}
                                >Login</a>
                                <a href="" className="btn btn-light py-md-2 px-md-5 animated slideInRight"
                                 style={{float: 'left', color: 'black'}}
                                >Começar Agora</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>

    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-4">
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item text-center pt-3">
                        <div className="p-4">
                            <i className="fa fa-3x fa-wrench text-primary mb-4"></i>
                            <h5 className="mb-3">Controle Total da Equipe</h5>
                            <p>Organize sua equipe de forma eficiente. Defina permissões, atribua funções e facilite a colaboração no gerenciamento de seus projetos 3D.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item text-center pt-3">
                        <div className="p-4">
                            <i className="fa fa-3x fa-building text-primary mb-4"></i>
                            <h5 className="mb-3">Upload e Gerenciamento de Modelos 3D</h5>
                            <p>Envie e organize seus modelos 3D diretamente na plataforma. Mantenha todos os arquivos atualizados e acessíveis para sua equipe, sem complicações.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item text-center pt-3">
                        <div className="p-4">
                            <i className="fa fa-3x fa-cogs text-primary mb-4"></i>
                            <h5 className="mb-3">Gestão de Pendências Sem E-mails</h5>
                            <p>Diga adeus ao caos dos e-mails! Com o Lumera 3D, acompanhe pendências do projeto em um só lugar. Centralize informações, delegue tarefas e mantenha sua equipe sempre alinhada com notificações automáticas.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="service-item text-center pt-3">
                        <div className="p-4">
                            <i className="fa fa-3x fa-book-open text-primary mb-4"></i>
                            <h5 className="mb-3">Guias e Suporte ao Usuário</h5>
                            <p>Tenha acesso a tutoriais, FAQs e suporte técnico sempre que precisar. Descubra como aproveitar ao máximo as funcionalidades do Lumera 3D.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{minHeight: '400px'}}>
                    <div className="position-relative h-100">
                        <img className="img-fluid w-100 h-100" src={about} alt="" style={{objectFit: 'cover'}} />
        
                    </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                    <h6 className="section-title bg-white className text-primary pe-3"><a style={{marginLeft: '12px'}}>Quem somos?</a></h6>
                    <h1 className="mb-4">Zeus Projects</h1>
                    <p className="mb-4">Nosso objetivo é facilitar projetos, oferecendo uma ferramenta essencial para organização de um projeto como todo.</p>
                    <p className="mb-4">Gerencie sua equipe conosco, em apenas alguns passos é possível em um só lugar enviar ordens de serviços, acompanhar o processo de criação, observar demandas e facilitar o processo de desenvolvimento.</p>
                    <div className="row gy-2 gx-4 mb-4">
                        <div className="col-sm-6">
                            <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Projetos de engenharia</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Projetos industriais</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Engenharia Cívil</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Construções</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Projetos eletrônicos</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Demanda de recursos</p>
                        </div>
                    </div>
                    <a className="btn btn-primary py-3 px-5 mt-2" href="">Ver Mais</a>
                </div>
            </div>
        </div>
    </div>

    <div className="container-xxl py-5 category"  style={{marginBottom: '200px', display: 'none'}}>
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="section-title bg-white text-center text-primary px-3">Categorias</h6>
                <h1 className="mb-5">Nossos serviços</h1>
            </div>
            <div className="row g-3">
                <div className="col-lg-7 col-md-6">
                    <div className="row g-3">
                        <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={cat1} alt="" /> 
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin: '1px'}} >
                                    <h5 className="m-0">Desenvolvimento</h5>
                                    <small className="text-primary"></small>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={cat2}  alt="" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin: '1px'}}>
                                    <h5 className="m-0">Assessoria</h5>
                                    <small className="text-primary"></small>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                            <a className="position-relative d-block overflow-hidden" href="">
                                <img className="img-fluid" src={cat3}  alt="" />
                                <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin: '1px'}}>
                                    <h5 className="m-0">Sistema de gestão</h5>
                                    <small className="text-primary"></small>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{minHeight: '350px'}}>
                    <a className="position-relative d-block h-100 overflow-hidden" href="">
                        <img className="img-fluid position-absolute w-100 h-100" src={cat4}  alt="" style={{objectFit: 'cover'}} />
                      
                        <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{margin: '1px'}}>
                            <h5 className="m-0">Marketing</h5>
                            <small className="text-primary"></small>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>


    <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s"
     
    >
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-3">Links Rápidos</h4>
                    <a
                     onClick={()=> navigate('/sobre')} 
                    className="btn btn-link" href="">Sobre</a>
                    <a
                      onClick={()=> navigate('/termos')}
                    className="btn btn-link" href="">Termos de Uso</a>
                    <a 
                      onClick={()=> navigate('/privacidade')}
                    className="btn btn-link" href="">Política de privacidade</a>
                    <a className="btn btn-link" href="">Como usar</a>
                </div>
                <div className="col-lg-3 col-md-6">

                    <div className="d-flex pt-2">
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            
     
            </div>
        </div>
        <div className="container">
            <div className="copyright">
         
            </div>
        </div>
    </div>
   
    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>







    </div>
  );
}

export default Home;
